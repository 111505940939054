import React, { useState, useEffect } from 'react'
import ReactHTMLParser from 'react-html-parser'

// CSS

const css = `
#maesh {
  font-weight: 400;
  font-family: "Quattrocento Sans", sans-serif;
  font-style: normal;
  letter-spacing: normal;
}

#qr-code path{
  -webkit-transform: scale(3.5);
  -moz-transform:    scale(3.5);
  -ms-transform:     scale(3.5);
  -o-transform:      scale(3.5);
  transform:         scale(3.5);
}

.maesh-logo {
  border-radius: 0% !important;
  margin: 0 5px !important;
}

.maesh-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.maesh-md-12,
.maesh-md-6,
.maesh-lg-6 {
  position: relative;
  width: 100%;
}

.maesh-details-text {
  font-size: 1.4rem;
  margin: 5px;
}

.maesh-info {
  display: flex;
  justify-content: center;
  align-items: center;
}
.shape-wrapper {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.shape-top {
  position: absolute;
  max-width: 560px;
}

.shape-right {
  position: absolute;
  max-width: 560px;
  background-color: #F6F9FC;
}
.shape-background {
  background-color: #f9fbfd;
  width: 800px;
  height: 500px;
  display: block;
  border-radius: 120px;
  z-index: -1;
}

.shape-right.shape-background {
  width: 80%;
  height: 150%;
}

@media (min-width: 576px) {
  .maesh-container {
    max-width: 540px;
  }

  .instructions {
    font-size: 1.2rem;
  }

  .maesh-details-text {
    font-size: 1.1rem;
  }

}

@media (min-width: 768px) {
  .maesh-container {
    max-width: 720px;
  }

  .maesh-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .maesh-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .maesh-md-none {
    display: none;
  }

  .maesh-md-block {
    display: block;
  }

  .image {
    width: 55%;
  }

  .instructions {
    font-size: 1.2rem;
  }


}

@media (min-width: 992px) {
  .maesh-container {
    max-width: 960px;
  }

  .maesh-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .image {
    width: 35%;
  }

  .instructions {
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .maesh-container {
    max-width: 1140px;
  }
}


.maesh-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

#error {
  position: absolute;
  margin: 10px auto;
  left: 0;
  right: 0;
  top: 0;
  width: fit-content;
  padding: 10px;
  font-size: 1em;
  letter-spacing: normal;
  font-weight: 700;
  background: white;
  color: #ff4a59;
  z-index: 9999;
  border-radius: 8px;
  border: 1px solid #ff4a59;
}

.showbox {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 100;
  margin: auto;
}

.loader {
  width: 60px;
}

.loader:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.circular {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@-webkit-keyframes color {

  100%,
  0% {
    stroke: #5d3edc;
  }

  33% {
    stroke: #ff4a59;
  }

  66% {
    stroke: #33ffc1;
  }
}

@keyframes color {

  100%,
  0% {
    stroke: #5d3edc;
  }

  33% {
    stroke: #ff4a59;
  }

  66% {
    stroke: #33ffc1;
  }
}

.info {
  border: 2px solid #33ffc1;
  border-radius: 8px;
  position: relative;
  height: -webkit-fill-available;
  overflow: auto;
}

img.pp-img {
  width: 15%;
  margin: 50px 25px;
}

.shape-wrapper {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.shape-top {
  position: absolute;
  max-width: 560px;
}

.shape-right {
  position: absolute;
  max-width: 560px;
  background-color: #f6f9fc;
}

.section {
  position: relative;
}

.section .shape-main {
  bottom: 0;
  width: 100%;
  height: 200%;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: translate(65%, 0%) rotate(-60deg);
  transform: translate(65%, 0%) rotate(-60deg);
  position: absolute;
  background-color: #5d3edc;
}

.section .shape-top {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: translate(60%, -30%) rotate(-60deg);
  transform: translate(60%, -30%) rotate(-60deg);
  left: 50%;
  background-color: #ff4a59;
}

.section .shape-right,
.wrapper .shape-right {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: translate(0%, -80%) rotate(60deg);
  transform: translate(0%, -80%) rotate(60deg);
}

.shape-background {
  background-color: #f9fbfd;
  width: 800px;
  height: 500px;
  display: block;
  border-radius: 120px;
  z-index: -1;
}

.shape-right.shape-background {
  width: 80%;
  height: 150%;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: translate(0%, -80%) rotate(60deg);
  transform: translate(0%, -80%) rotate(60deg);
}

.dot {
  height: 120px;
  width: 120px;
  background-color: #f6f9fc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.banks {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 5%;
  border-radius: 20%;
  margin: 3px;
}

@media screen and (max-width: 1024px) {
  .banks {
    width: 6%;
  }
}

@media screen and (max-width: 670px) {
  .banks {
    width: 6%;
  }

  .details {
    font-size: 1em;
  }

  .details h1 {
    font-size: 1.5em;
  }

  .instructions {
    font-size: 1.2rem;
  }

  .maesh-details-text {
    font-size: 1.1rem;
  }
}

.footer {
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

/* Button */
.button,
.button:focus {
  width: 20%;
  min-width: 100px;
  padding: 0px 10px;
  border-radius: 10px;
  border: 3px;
  background: #ff4a59;
  border-style: solid;
  border-color: #ff4a59;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
}

.button:hover {
  background: #ffffff;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

.cancel,
.cancel:focus {
  background: #ebebeb;
  border-color: #ebebeb;
  color: #000000;
}

@media (max-width: 768px) {

  .button,
  .button:focus {
    padding: 3px 6px;
  }
}

@media screen and (min-width: 1025px) {
  .mobile {
    display: none;
  }

  .desktop {
    display: inline-block;
  }
}

@media screen and (max-width: 1024px) {
  .mobile {
    display: none;
    align-content: center;
  }

  .desktop {
    display: none;
  }
}

.instructions {
  font-weight: 300;
  margin-top: 10px;
}

/* Vertically align the 2 columns on the payment page */
.maesh-container .row {
  display: flex;
  align-items: center;
}

/* Transient gradient in button */
#success,
#close {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #5d3edc, #ffc371);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  border: 0px;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

#cancel {
  background-color: #d6d4d4;
  border-color: #d6d4d4;
}

input,
textarea {
  border-radius: 0.3rem;
  padding-left: 5px;
}

.image {
  width: 100%;
}

.middle {
  text-align: center;
  display: flex;
  justify-content: center;
}

.qr-parent {
  position: relative;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
}

.qr {
  position: relative;
  top: 0;
  left: 0;
}

.paynow {
  position: absolute;
  top: 80px;
  left: 70px;
  background-color: #ffffff;
  width: 30%;
  padding: 5px;
}
.footer {
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
`

const ID = 'maesh_style'
const font_ID = 'maesh_font'

;(() => {
  if (typeof window === 'undefined') {
    return
  }

  if (!document.getElementById(font_ID)) {
    const head = document.head || document.getElementsByTagName('head')[0]
    const link = document.createElement('link')
    link.id = font_ID
    link.rel = 'stylesheet'
    link.href =
      'https://fonts.googleapis.com/css?family=Quattrocento+Sans:400,700&amp;display=swap'
    if (head) {
      head.appendChild(link)
    }
  }

  if (!document.getElementById(ID)) {
    const head = document.head || document.getElementsByTagName('head')[0]
    const sprc = document.createElement('style')
    sprc.id = ID
    sprc.type = 'text/css'
    if (sprc.styleSheet) {
      sprc.styleSheet.cssText = css
    } else {
      sprc.appendChild(document.createTextNode(css))
    }
    if (head) {
      head.appendChild(sprc)
    }
  }
})()

// COMPONENTS

const MaeshSpinner = () => {
  return (
    <div className='showbox' id='loader'>
      <div className='loader'>
        <svg className='circular' viewBox='25 25 50 50'>
          <circle
            className='path'
            cx='50'
            cy='50'
            r='20'
            fill='none'
            strokeWidth='5'
            strokeMiterlimit='10'
          />
        </svg>
      </div>
    </div>
  )
}

const MaeshError = ({ message }) => {
  return <div id='error'>{message}</div>
}

const MaeshInfo = ({ mobileFlag }) => {
  return (
    <div className='maesh-lg-12 maesh-md-12 maesh-info'>
      <div style={{ textAlign: 'center' }}>
        {mobileFlag ? (
          <h1 className='instructions' id='mobile'>
            Take a <span style={{ fontWeight: 700 }}>screenshot</span> of the QR
            and upload it in your
            <span style={{ fontWeight: 700 }}>bank app</span>'s Scan & Pay:
          </h1>
        ) : (
          <h1 className='instructions' id='desktop'>
            Grab your
            <span style={{ fontWeight: 700, margin: '0 3px' }}>phone</span> 📲
            and use
            <span
              style={{ fontWeight: 700, fontSize: 'larger', margin: '0 5px' }}
            >
              any bank app
            </span>
            to scan the QR code & pay
          </h1>
        )}

        <div style={{ margin: '15px', textAlign: 'center' }}>
          <img
            src='https://js.maesh.io/prod/component/static/dbs.png'
            alt='DBS logo'
            className='banks'
          />
          <img
            src='https://js.maesh.io/prod/component/static/posb.jpg'
            alt='POSB logo'
            className='banks'
          />
          <img
            src='https://js.maesh.io/prod/component/static/paylah.jpg'
            alt='PayLah! logo'
            className='banks'
          />
          <img
            src='https://js.maesh.io/prod/component/static/ocbc.jpg'
            alt='OCBC Bank logo'
            className='banks'
          />
          <img
            src='https://js.maesh.io/prod/component/static/uob.jpg'
            alt='UOB logo'
            className='banks'
          />
          <img
            src='https://js.maesh.io/prod/component/static/citi-bank.jpg'
            alt='Citi Bank logo'
            className='banks'
          />
          <img
            src='https://js.maesh.io/prod/component/static/hsbc.jpg'
            alt='HSBC logo'
            className='banks'
          />
          <img
            src='https://js.maesh.io/prod/component/static/standard-chartered.jpg'
            alt='Standard Chartered logo'
            className='banks'
          />
          <img
            src='https://js.maesh.io/prod/component/static/maybank.jpg'
            alt='Maybank logo'
            className='banks'
          />
          <img
            src='https://js.maesh.io/prod/component/static/bank-of-china.jpg'
            alt='Bank of China logo'
            className='banks'
          />
          <img
            src='https://js.maesh.io/prod/component/static/icbc.jpg'
            alt='ICBC logo'
            className='banks'
          />
        </div>
        <h6>
          Powered by
          <a href='https://maesh.io' target='_blank'>
            <img
              className='maesh-logo banks'
              src='https://js.maesh.io/prod/component/static/maesh.png'
              alt='Maesh'
            />
          </a>
        </h6>
      </div>
    </div>
  )
}

const MaeshQRDetails = ({ data }) => {
  return (
    <div className="maesh-lg-12 maesh-md-12">
      <div style={{ padding: '25px 0' }}>
        <div className='middle'>
          <div className='qr-parent'>
            <div id='qr-code'>{ReactHTMLParser(data['qr'])}</div>
            <img className="paynow" src="https://js.maesh.io/prod/component/static/paynow-2-lines.png"
              alt="PayNow Logo"
            />
          </div>
        </div>
      </div>
      <div className='details' style={{ textAlign: 'center' }}>
        <h1>
          S$ <span id='amount'>{(data['amount'] / 100).toFixed(2)}</span>
        </h1>
        <div className='maesh-details-text'>
          <strong>To:</strong> Maesh Pte. Ltd. collecting for {data['merchant']}
        </div>
        <div className='maesh-details-text'>
          <strong>Transaction ID: </strong>
          <span id='transactionId'>DICN{data['transactionId']}</span>
        </div>
        <div className='maesh-details-text'>
          <strong>Page Expiry at: </strong>
          <span id='time'>
            {data['timeStamp']}
          </span>
        </div>
      </div>
    </div>
  )
}

const MaeshContent = ({ maeshData, mobileFlag }) => {
  return (
    <div id='content' style={{ width: '100%' }}>
      <div className='maesh-container'>
        <div className='maesh-row'>
          <MaeshInfo mobileFlag={mobileFlag} />
          <MaeshQRDetails data={maeshData} />
        </div>
      </div>
    </div>
  )
}

const MaeshExpired = () => {
  return (
    <div
      className='maesh-container'
      style={{ textAlign: 'center' }}
      id='expired'
    >
      <div className='shape-wrapper'>
        <div className='shape shape-background shape-right'></div>
      </div>
      <section className='section'>
        <div className='maesh-container'>
          <h1>Go fish! That payment page expired</h1>
        </div>
        <div class='maesh-container'>
          <div class='maesh-row' style={{ justifyContent: 'center' }}>
            <img
              className='image'
              src='https://js.maesh.io/prod/component/static/abstrakt-design-first-issue-1-06.svg'
              style={{ width: '70%' }}
            />
          </div>
        </div>
      </section>
      <footer>
        <div className='maesh-container footer'>
          <h6>
            Lower fees with{' '}
            <a href='https://maesh.io' target='_blank'>
              <img
                style={{ height: '40px' }}
                src='https://js.maesh.io/prod/component/static/maesh.png'
                alt='Maesh'
              />
            </a>
          </h6>
          <h6 style={{ marginTop: '10px' }}>
            <a
              style={{ color: '#5d3edc' }}
              href='https://maesh.io/consumers.html'
              target='_blank'
            >
              <strong>What is Maesh?</strong>
            </a>
          </h6>
        </div>
      </footer>
    </div>
  )
}

// PUBLIC FUNCTIONS

function Maesh({ data }) {
  // Variables

  let jwt

  // Default defaults
  const defaults = {
    method: 'POST',
    url: 'https://api.staging2.maesh.io',
    createJWT: {
      first: 'mutation { createJwt(refreshToken:"',
      second: '") { token } }'
    },
    createIntent: {
      first: 'mutation { createIntent ( input: { amount: ',
      second: ', currency: "',
      third: '", gotoUrl: "',
      fourth: '",  referenceCode: "',
      fifth: '" }) { intent { transactionId }  sgqr merchant } }'
    },
    getIntent: {
      first: '{ getIntent( transactionId: "',
      second: '") { status gotoUrl}}'
    },
    timeout: 1000,
    refreshToken: ''
  }

  /**
   * Construct body for requests
   * @private
   * @param {String} type Type of Request
   * @param {Object} data  A set of values for creating the body [optional]
   * @return {Object}      An object for request payload
   */
  const createBody = function (type, data = {}) {
    let body = {}
    if (type === 'createJwt') {
      body['query'] =
        defaults.createJWT.first + data.api_key + defaults.createJWT.second
    }
    if (type === 'createIntent') {
      body['query'] =
        defaults.createIntent.first +
        data.amount +
        defaults.createIntent.second +
        data.currency +
        defaults.createIntent.third +
        data.gotoUrl +
        defaults.createIntent.fourth +
        data.referenceCode +
        defaults.createIntent.fifth
    }
    if (type === 'getIntent') {
      body['query'] =
        defaults.getIntent.first +
        data.transactionId +
        defaults.getIntent.second
    }
    return body
  }

  /**
   * Create a transaction
   * @private
   * @param   {Object}   data  Object with data to send requests
   */
  async function transaction(data) {
    await fetch(defaults.url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(createBody('createJwt', data))
    })
      .then(async (response) => await response.json())
      .then((json) => {
        if (json['errors']) {
          seterrorFlag(true)
          setloadFlag(true)
        }
        jwt = json['data']['createJwt']['token']
        sessionStorage.setItem('maesh_one', jwt)
        fetch(defaults.url, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Authorization: 'JWT ' + json['data']['createJwt']['token'],
            'User-Agent': 'local/v1',
            Origin: 'local.',
            Accept: 'application/javascript'
          },
          body: JSON.stringify(createBody('createIntent', data))
        })
          .then((response) => response.json())
          .then((json) => {
            if (json['errors']) {
              seterrorFlag(true)
              setloadFlag(true)
            }
            sessionStorage.setItem(
              'maesh_two',
              json['data']['createIntent']['intent']['transactionId']
            )

            // Generate Time stamp
            var now = new Date();
            now.setMinutes(now.getMinutes() + 15); // timestamp
            var expiry_timestamp = now.toDateString().slice(4) + ' ' + now.toLocaleTimeString();

            setMaeshData((maeshData) => ({
              ...maeshData,
              qr: json['data']['createIntent']['sgqr'],
              transactionId:
                json['data']['createIntent']['intent']['transactionId'],
              amount: data.amount,
              merchant: json['data']['createIntent']['merchant'],
              timeStamp : expiry_timestamp
            }))
            setloadFlag(false)
            seterrorFlag(false)
            status(json['data']['createIntent']['intent']['transactionId'], jwt)
          })
          .catch((e) => {
            seterrorFlag(true)
            setloadFlag(true)
          })
      })
      .catch((e) => {
        seterrorFlag(true)
        setloadFlag(true)
      })
  }

  /**
   * Check status of Payment
   * @private
   * @param {String} transaction_id Transaction ID
   * @param {String} token JWT token to authorise the request
   */

  const status = function (transaction_id, token) {
    fetch(defaults.url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'JWT ' + token
      },
      body: JSON.stringify(
        createBody('getIntent', { transactionId: transaction_id })
      )
    })
      .then((response) => response.json())
      .then((json) => {
        if (json['errors']) {
          throw new Error('Expired')
        }
        if (json['data']['getIntent']['status'] === 'Pending') {
          setTimeout(function () {
            status(transaction_id, token)
          }, defaults.timeout)
        } else {
          window.location.replace(json['data']['getIntent']['gotoUrl'])
        }
      })
      .catch((e) => {
        setexpiredFlag(true)
      })
  }

  const [loadFlag, setloadFlag] = useState(true)
  const [errorFlag, seterrorFlag] = useState(false)
  const [mobileFlag, setmobileFlag] = useState(false)
  const [expiredFlag, setexpiredFlag] = useState(false)
  const [maeshData, setMaeshData] = useState({})
  const [errorMessage, setErrorMessage] = useState('Something went wrong!')
  const isMobile = /iPhone|iPad|iPod|webOS|Android|BlackBerry|Windows Phone/i.test(
    navigator.userAgent
  )

  useEffect(() => {
    sessionStorage.removeItem('maesh_one')
    sessionStorage.removeItem('maesh_two')
    if (isMobile) {
      setmobileFlag(true)
    }
    if (data.referenceCode.length > 10) {
        seterrorFlag(true);
        setloadFlag(true);
        setErrorMessage('Reference Code cannot be longer than 10 characters.');
      }

    else if (!Number.isInteger(data.amount)){
        seterrorFlag(true);
        setloadFlag(true);
        setErrorMessage('Amount must be an integeer.');
      }

    else if (data.currency !== 'SGD'){
        seterrorFlag(true);
        setloadFlag(true);
        setErrorMessage("Only 'SGD' allowed as currency.");
      }
      else{
        transaction(data);
      }
  }, [])

  return (
    <div id='maesh' style={{ width: 'auto' }}>
      <div className='maesh-row info'>
        {errorFlag ? <MaeshError message={errorMessage} /> : null}
        {loadFlag ? (
          <MaeshSpinner />
        ) : expiredFlag ? (
          <MaeshExpired />
        ) : (
          <MaeshContent maeshData={maeshData} mobileFlag={mobileFlag} />
        )}
      </div>
    </div>
  )
}

async function handleCallback() {
  // Default defaults

  const transaction_id = sessionStorage.getItem('maesh_two')
  const token = sessionStorage.getItem('maesh_one')

  const defaults = {
    method: 'POST',
    url: 'https://api.staging2.maesh.io',
    getIntent: {
      first: '{ getIntent( transactionId: "',
      second: '") { status gotoUrl}}'
    }
  }

  /**
   * Construct body for requests
   * @private
   * @param {String} type Type of Request
   * @param {Object} data  A set of values for creating the body [optional]
   * @return {Object}      An object for request payload
   */
  const createBody = function (type, data = {}) {
    let body = {}
    if (type === 'getIntent') {
      body['query'] =
        defaults.getIntent.first +
        data.transactionId +
        defaults.getIntent.second
    }
    return body
  }

  try {
    const response = await fetch(defaults.url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'JWT ' + token
      },
      body: JSON.stringify(
        createBody('getIntent', { transactionId: transaction_id })
      )
    })
    const json = await response.json()

    sessionStorage.removeItem('maesh_one')
    sessionStorage.removeItem('maesh_two')
    if (json['errors']) {
      throw new Error('Invalid')
    }
    return json['data']['getIntent']['status']
  } catch (e) {
    return 'Error'
  }
}

export default Maesh
export const maeshStatus = handleCallback
